import Vue from "vue";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import router from "@/router";

const sentryOptions = {
    Vue,
    dsn: process.env.NODE_ENV !== "development" ? process.env.VUE_APP_SENTRY_DSN : null,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost:8080", "zubut.com", "staging.zubut.com", /^\//]
        })
    ],
    tracesSampleRate: 0.1,
    environment: process.env.NODE_ENV
};

if (process.env.NODE_ENV === "staging") {
    sentryOptions.debug = true;
}

Sentry.init(sentryOptions);

// Wrapping the Sentry methods allows us to add custom behaviour or easily change
// the error tracking provider if needed
const captureError = err => Sentry.captureException(err);
const setUser = user => Sentry.setUser(user);
const clearUser = () => Sentry.configureScope(scope => scope.setUser(null));

Vue.prototype.$captureError = captureError;

export { captureError, setUser, clearUser };
