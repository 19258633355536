import Vue from "vue";
import Router from "vue-router";

const MainLayout = () => import("@/layouts/MainLayout");

const Fulfillment = () => import("@/views/Fulfillment");
const ForBusinesses = () => import("@/views/ForBusinesses");
const ForDevelopers = () => import("@/views/ForDevelopers");
const ForDrivers = () => import("@/views/ForDrivers");
const Home = () => import("@/views/Home");
const HowItWorks = () => import("@/views/HowItWorks");
const PreRegistration = () => import("@/views/PreRegistration");
const RegisterBusinesss = () => import("@/views/RegisterBusinesss");
const TermsAndConditions = () => import("@/views/TermsAndConditions");
const CookiesPolicy = () => import("@/views/CookiesPolicy");
const Contract = () => import("@/views/Contract");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy");
const Tracking = () => import("@/views/Tracking");
const Referrals = () => import("@/views/Referrals");

Vue.use(Router);

export const routes = [
    {
        path: "/",
        component: MainLayout,
        redirect: {
            name: "fulfillment"
        },
        children: [
            {
                path: "/",
                alias: "/fulfillment",
                name: "fulfillment",
                component: Fulfillment
            },
            {
                path: "/envios",
                name: "home",
                component: Home
            },
            {
                path: "/como-funciona",
                name: "como-funciona",
                component: HowItWorks
            },
            {
                path: "/para-empresas",
                name: "para-empresas",
                component: ForBusinesses
            },
            {
                path: "/para-mensajeros",
                name: "para-mensajeros",
                component: ForDrivers
            },
            {
                path: "/para-desarrolladores",
                name: "para-desarrolladores",
                component: ForDevelopers
            },
            {
                path: "/registrar-empresa",
                name: "registrar-empresa",
                component: RegisterBusinesss
            },
            {
                path: "/terminos-y-condiciones",
                name: "terminos-y-condiciones",
                component: TermsAndConditions
            },
            {
                path: "/politica-de-cookies",
                name: "politica-de-cookies",
                component: CookiesPolicy
            },
            // {
            //     path: "/contrato-prestacion-servicio",
            //     name: "contrato-prestacion-servicio",
            //     component: Contract
            // },
            {
                path: "/aviso-de-privacidad",
                name: "aviso-de-privacidad",
                component: PrivacyPolicy
            },
            {
                path: "/rastrea-tu-envio",
                name: "tracking",
                component: Tracking
            }
        ]
    },
    {
        path: "/contacto",
        name: "contact",
        component: PreRegistration
    },
    {
        path: "/referidos/*",
        name: "referrals",
        component: Referrals
    },
    {
        path: "*",
        redirect: {
            name: "home"
        }
    }
];

export default new Router({
    mode: "history",
    scrollBehavior(to) {
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },
    routes
});
