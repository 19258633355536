import Vue from "vue";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";

const accessToken = `${process.env.VUE_APP_PRISMIC_TOKEN}`;
const endpoint = "https://zubut.prismic.io/api/v2"; // Use your repo name

// Register plugin
Vue.use(PrismicVue, {
    endpoint,
    apiOptions: { accessToken },
    linkResolver
});
