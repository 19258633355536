import Vue from "vue";
import App from "./App";
import router from "./router";
import "./plugins";

import ZButton from "@/components/ZButton";
import ZLoading from "@/components/ZLoading";

/* Globally used components */
Vue.component("z-loading", ZLoading);
Vue.component("z-button", ZButton);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
