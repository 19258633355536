import { captureError } from "@/plugins/error-tracking";
function facebook(eventName, contentName) {
    try {
        fbq("track", eventName, { content_name: contentName });
    } catch (error) {
        capture.error(error);
    }
}

function google(eventCategory, eventLabel) {
    try {
        gtag("event", "click", {
            event_category: eventCategory,
            event_label: eventLabel
        });
    } catch (error) {
        captureError(error);
    }
}

const trackActionWithAnalytics = {
    facebook,
    google
};

export default trackActionWithAnalytics;
