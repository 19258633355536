<template>
    <a
        aria-label="whatsapp"
        class="whats-app-chat"
        role="button"
        :href="wapLink"
        target="_blank"
        @click="handleClick"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
            <path
                fill="#fff"
                d="M4.868 43.303l2.694-9.835a18.941 18.941 0 01-2.535-9.489C5.032 13.514 13.548 5 24.014 5a18.867 18.867 0 0113.43 5.566A18.866 18.866 0 0143 23.994c-.004 10.465-8.522 18.98-18.986 18.98-.001 0 0 0 0 0h-.008a18.965 18.965 0 01-9.073-2.311l-10.065 2.64z"
            />
            <path
                fill="#fff"
                d="M4.868 43.803a.499.499 0 01-.482-.631l2.639-9.636a19.48 19.48 0 01-2.497-9.556C4.532 13.238 13.273 4.5 24.014 4.5a19.367 19.367 0 0113.784 5.713A19.362 19.362 0 0143.5 23.994c-.004 10.741-8.746 19.48-19.486 19.48a19.535 19.535 0 01-9.144-2.277l-9.875 2.589a.457.457 0 01-.127.017z"
            />
            <path
                fill="#cfd8dc"
                d="M24.014 5a18.867 18.867 0 0113.43 5.566A18.866 18.866 0 0143 23.994c-.004 10.465-8.522 18.98-18.986 18.98h-.008a18.965 18.965 0 01-9.073-2.311l-10.065 2.64 2.694-9.835a18.941 18.941 0 01-2.535-9.489C5.032 13.514 13.548 5 24.014 5m0-1C12.998 4 4.032 12.962 4.027 23.979a20.01 20.01 0 002.461 9.622L3.903 43.04a.998.998 0 001.219 1.231l9.687-2.54a20.026 20.026 0 009.197 2.244c11.024 0 19.99-8.963 19.995-19.98A19.856 19.856 0 0038.153 9.86 19.869 19.869 0 0024.014 4z"
            />
            <path
                fill="#40c351"
                d="M35.176 12.832a15.673 15.673 0 00-11.157-4.626c-8.704 0-15.783 7.076-15.787 15.774a15.738 15.738 0 002.413 8.396l.376.597-1.595 5.821 5.973-1.566.577.342a15.75 15.75 0 008.032 2.199h.006c8.698 0 15.777-7.077 15.78-15.776a15.68 15.68 0 00-4.618-11.161z"
            />
            <path
                fill="#fff"
                fill-rule="evenodd"
                d="M19.268 16.045c-.355-.79-.729-.806-1.068-.82-.277-.012-.593-.011-.909-.011-.316 0-.83.119-1.265.594-.435.475-1.661 1.622-1.661 3.956 0 2.334 1.7 4.59 1.937 4.906.237.316 3.282 5.259 8.104 7.161 4.007 1.58 4.823 1.266 5.693 1.187.87-.079 2.807-1.147 3.202-2.255.395-1.108.395-2.057.277-2.255-.119-.198-.435-.316-.909-.554s-2.807-1.385-3.242-1.543c-.435-.158-.751-.237-1.068.238-.316.474-1.225 1.543-1.502 1.859-.277.317-.554.357-1.028.119s-2.002-.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285-.277-.474-.03-.731.208-.968.213-.213.474-.554.712-.831.237-.277.316-.475.474-.791.158-.317.079-.594-.04-.831-.117-.238-1.039-2.584-1.461-3.522z"
                clip-rule="evenodd"
            />
        </svg>
    </a>
</template>

<script>
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";
export default {
    name: "WhatsAppChat",

    data() {
        return {
            textMessage: "Hola! Me gustaría obtener más información sobre sus servicios",
            wapNumber: "5213324322135"
        };
    },

    computed: {
        wapLink() {
            return `https://wa.me/${this.wapNumber}?text=${encodeURIComponent(this.textMessage)}`;
        }
    },
    methods: {
        handleClick() {
            trackActionWithAnalytics.facebook("Contact", "whatsapp");
            trackActionWithAnalytics.google("telefono", "whatsapp");
        }
    }
};
</script>

<style>
.whats-app-chat {
    position: fixed;
    bottom: 26px;
    right: 26px;
    cursor: pointer;
}

.whats-app-chat svg {
    transform: scale(1.4);
}
</style>
