<template>
    <button
        :class="[
            'z-button',
            { disabled: disabled },
            { uppercase: uppercase },
            { 'weight-bold': bold === true },
            { 'weight-normal': bold === 'normal' },
            { 'font-size-tiny': fontSize === 'tiny' },
            size,
            variantClass
        ]"
        :disabled="disabled"
        :type="type"
        v-on="$listeners"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "ZButton",
    props: {
        bold: {
            type: [Boolean, String],
            default: false,
            validator: value => [true, false, "normal", "bold"].indexOf(value) !== -1
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "sm",
            validator: value => ["sm", "md", "lg"].indexOf(value) !== -1
        },
        type: {
            type: String,
            default: "button",
            validator: value => ["button", "submit", "reset"].indexOf(value) !== -1
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: "primary",
            validator: value =>
                ["primary", "secondary", "alice-blue", "basic"].indexOf(value) !== -1
        },
        fontSize: {
            type: String,
            default: "normal",
            validator: value => ["tiny", "normal"].indexOf(value) !== -1
        }
    },
    data() {
        return {
            variantClass: `z-button-${this.variant}`
        };
    }
};
</script>

<style lang="scss">
$light-blue: #3c97ff;
$light-grey: #f1f1f1;
$mid-grey: #d8d8d8;
$text-color: #464646;
$text-grey: #a0a0a0;
$zubut-blue: #0077fe;

.z-button {
    height: 32px;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 0.875em;
    font-weight: 600;
    padding: 0em 1em;
    line-height: 16px;

    &.sm {
        height: 32px;
    }

    &.md {
        height: 34px;
    }

    &.lg {
        height: 38px;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.weight-bold {
        font-weight: 600;
    }

    &.weight-normal {
        font-weight: 500;
    }

    &.font-size-tiny {
        font-size: 11px;
    }
}

.z-button-primary {
    background: $zubut-blue;
    color: #fff;
    cursor: pointer;

    &.disabled {
        background: $light-grey;
        color: $text-grey;
        cursor: default;

        &:hover {
            background: $light-grey;
        }
    }

    &:hover {
        background: $light-blue;
    }
}

.z-button-secondary {
    background-color: #fdfeff;
    color: $zubut-blue;
    box-shadow: 0 2px 5px 1px rgba(164, 201, 241, 0.55);
    cursor: pointer;

    &.disabled {
        background: $light-grey;
        color: $text-grey;
        cursor: default;

        &:hover {
            background-color: #f6f9ff;
        }
    }

    &:hover {
        background: #f6f9ff;
    }
}

.z-button-alice-blue {
    background-color: $alice-blue;
    color: $zubut-blue;

    &:hover {
        background-color: $light-cyan;
    }
}

.z-button-basic {
    background: white;
    color: #8190a1;
    border: 1px solid rgba(129, 144, 161, 0.5);
    border-radius: 4px;
    cursor: pointer;

    &.disabled {
        background: $light-grey;
        color: $text-grey;
        cursor: default;

        &:hover {
            background-color: #f6f9ff;
        }
    }

    &:hover,
    &.active {
        background-color: rgba(68, 154, 255, 0.1);
        border: 1px solid rgba(68, 154, 255, 0.3);
        color: #449aff;

        img {
            filter: brightness(100%) sepia(100) saturate(100) hue-rotate(260deg);
        }
    }
}
</style>
