<template>
    <div id="app">
        <router-view />
        <whats-app-chat />
    </div>
</template>

<script>
import WhatsAppChat from "@/components/WhatsAppChat";
export default {
    name: "App",
    metaInfo: {
        title: "Tu flotilla sin inversión",
        titleTemplate: "Zubut | %s",
        meta: [
            { charset: "utf-8" },
            {
                name: "description",
                content: `Somos la plataforma de envíos que facilita la logística de tu empresa, restaurante, o eCommerce a nivel local.`
            }
        ]
    },
    components: {
        WhatsAppChat
    }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900&display=swap");

$zubut-blue: #0077fe;

* {
    box-sizing: border-box;
}

#app {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-display: swap;

    .navbar {
        .nav-link {
            color: #1c1c1c !important;
        }

        #nav_collapse {
            text-align: left;
            font-size: 13px;
        }

        .btn-login {
            font-size: 14px;
            color: #1c1c1c !important;
            padding-left: 0px;
        }

        .btn-register-bussines {
            background: $zubut-blue;
            color: #fff;
            border: none;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: bold;
            line-height: 20px;
            letter-spacing: 0.4px;
        }
    }
}

#nav {
    padding: 30px;
    a {
        font-weight: bold;
        color: #2c3e50;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

a.anchor {
    display: block;
    position: relative;
    top: -65px;
    visibility: hidden;
}
/* Background color */
.z-background-white {
    background-color: #fff;
}

.z-background-light-grey {
    background-color: #f3f5f9;
}

.z-background-dark {
    background-color: #213043;
}

.z-background-blue {
    background-color: #0077fe;
}

.z-background-light-blue {
    background-color: #449aff;
}

/* Font color */
.z-text-white {
    color: #fff;
}

.z-text-light {
    color: #94a1b0;
}

.z-text-blue {
    color: $zubut-blue;
}

.z-text-color-light-blue {
    color: #449aff;
}
/* Font Size */
.z-text-smaller {
    font-size: 12px;
}

.z-text-small {
    font-size: 14px;
}

.z-text-medium {
    font-size: 18px;
}

.z-text-bigger {
    font-size: 24px;
}

.z-text-big {
    font-size: 22px;
}

/* Font Boldness */
.z-text-thin {
    font-weight: 300;
}

.z-text-normal {
    font-weight: 500;
}

.z-text-bold {
    font-weight: 600;
}

/* Layout */
.no-gutters {
    padding: 0px;
}
</style>
