import Vue from "vue";
import {
    BButton,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BNav,
    BNavItem,
    BNavbar,
    BNavbarNav,
    BNavbarToggle,
    BContainer,
    BCol,
    BRow
} from "bootstrap-vue";
import "./index.scss";

// Button
Vue.component("b-button", BButton);

// Form
Vue.component("b-form", BForm);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-form-checkbox-group", BFormCheckboxGroup);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-input", BFormInput);
Vue.component("b-form-select", BFormSelect);

// Layout
Vue.component("b-container", BContainer);
Vue.component("b-col", BCol);
Vue.component("b-row", BRow);

// Navbar
Vue.component("b-nav", BNav);
Vue.component("b-nav-item", BNavItem);
Vue.component("b-navbar", BNavbar);
Vue.component("b-navbar-nav", BNavbarNav);
Vue.component("b-navbar-toggle", BNavbarToggle);
