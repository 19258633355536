var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({class:[
        'z-button',
        { disabled: _vm.disabled },
        { uppercase: _vm.uppercase },
        { 'weight-bold': _vm.bold === true },
        { 'weight-normal': _vm.bold === 'normal' },
        { 'font-size-tiny': _vm.fontSize === 'tiny' },
        _vm.size,
        _vm.variantClass
    ],attrs:{"disabled":_vm.disabled,"type":_vm.type}},_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }