import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es";

Vue.use(VeeValidate, {
    fieldsBagName: "veeFields",
    errorBagName: "veeErrors"
});

Validator.localize("es", es);
Validator.extend("phone", {
    getMessage: "El teléfono debe contener por lo menos 10 dígitos",
    validate: (phone, [resultPhone]) => {
        return resultPhone.replace(/[\s()\-+]/g, "").length >= 10;
    }
});
Validator.extend("website", {
    getMessage: "El formato del sitio web no es correcto",
    validate: website => {
        return website.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/
        )
            ? true
            : false;
    }
});
